import classNames from "classnames/bind";
import { Stage } from "../../stage/stage";
import { BlocksContactAssistantStage } from "../../../__generated__/contentful-types";
import styles from "./contact-assistant-stage.module.css";
// import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import { analyticsClick } from "../../../helpers/analytics";
import { isExternalLink } from "../../../helpers/isExternalLink";

const cx = classNames.bind(styles);

export const ContactAssistantStage = ({
  block,
}: {
  block: BlocksContactAssistantStage;
}) => {
  const onClickHandler = () =>
    analyticsClick({
      value: block.linkText ?? "",
      clickUrl: block.linkUrl ?? "",
      elementPosition: block?.__typename ?? "",
      method: isExternalLink(block.linkUrl ?? "")
        ? "Outbound: TRUE"
        : "Outbound: FALSE",
      contentType: "CTA",
    });

  return (
    <div className={cx("container")}>
      <Stage
        backgroundImage={block.backgroundImage?.url}
        name={block.name}
        title={block.title}
        linkUrl={block.linkUrl}
        description={block.description}
        linkText={block.linkText}
        withRbButton={true}
        linkOnClick={onClickHandler}
      />
      {/* High Sugar mark currently not implimented */}
      {/* <DietaryMarks
        kind="high-sugar"
        className={cx("dietary-mark-high-sugar")}
        sugarFree={false}
      /> */}
    </div>
  );
};
